import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AI萧助手',
    //component: () => import('../views/chatPage/index')
    component: () => import('@/views/HomeView.vue'),

  },
  {
    path: '/index',
    name: 'AI萧助手1',
    component: () => import('@/views/chatPage/index'),
  },
  {
    path: '/viewPdf',
    name: 'AI萧助手',
    component: () => import('../views/viewPdf/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



router.afterEach((to, from) => {
  console.log(to, from)
  document.title = 'AI萧助手-大模型知识引擎';
})
export default router
